import axios, { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';

axios.interceptors.response.use((res) => res, (error) => {
  let toastMessage = '';
  if (error.response.status === 403) {
    toastMessage = "403 Error - Access Denied";
  } else {
    toastMessage = error.response.status + ' Error - ' + error.response.statusText;
  }
  if (error.response?.data?.message) {
    toastMessage = toastMessage + ' ('+ error.response?.data?.message +') ';
  }
  if (error.response.status === 409) {
    // Duplicate entry error message will be displayed in form itself
    const errorMsg = error.response?.data?.message;
    const isDuplicateError = errorMsg.includes("Duplicate") || errorMsg.includes("duplicate");
    if(!isDuplicateError){
      toast.error(toastMessage);
    }
  }else{
    toast.error(toastMessage);
  }
  return Promise.reject(error);
});

const httpService = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};

export default httpService;
