import PubSub from "pubsub-js";

let menuCollapse = false;
let displayLoader: any = false;
let loadedClient: any;
let selectedJourney: object;
let apiBaseUrl;

const getApiBaseUrl = async () => {
  if (!apiBaseUrl) {
    const configProperties = await fetch("/client-portal.json").then(
      (response) => response.json()
    );
    apiBaseUrl = configProperties["api-base-url"];
  }
  return apiBaseUrl;
};

const menuIconClick = () => {
  menuCollapse = !menuCollapse;
  publish("menuCollapse", menuCollapse);
  return menuCollapse;
};

const setLoading = (data = "") => {
  displayLoader = data;
  publish("displayLoader", displayLoader);
  return displayLoader;
};

const setLoadedClient = (loaded) => {
  loadedClient = loaded;
  publish("loadedClient", loadedClient);
  return loadedClient;
}

const setSelectedJourney = (journey => selectedJourney = journey);
const getSelectedJourney = () => selectedJourney;

const getLoadedClient = () => loadedClient;

const publish = (event, data) => PubSub.publish(event, data);
const subscribe = (event, callback) => PubSub.subscribe(event, callback);
const unsubscribe = (event) => PubSub.unsubscribe(event);

const rootConfigProps = {
  getApiBaseUrl,
  menuCollapse,
  menuIconClick,
  publish,
  subscribe,
  unsubscribe,
  PubSub,
  displayLoader,
  setLoading,
  setLoadedClient,
  getLoadedClient,
  setSelectedJourney,
  getSelectedJourney,
};

export default rootConfigProps;
