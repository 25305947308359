import { useContext, useEffect, useState } from "react";
import { RootContext } from "../../contexts/rootContext";

function Loading() {

    const rootData = useContext(RootContext);
    const [loadingStatus, setLoadingStatus] = useState('none');

    useEffect(() => {
        const status = rootData.displayLoader ? 'block' : 'none';
        setLoadingStatus(status);
    },[rootData])

     rootData.subscribe('displayLoader', function (msg, displayLoader) {
         const status = displayLoader ? 'block' : 'none';
         setLoadingStatus(status);
     });
    
    return (
        <div className="load-wrapp" id="loading" style={{display: loadingStatus}}>
            <div className="setloader">
                <div className="load-4">
                    <div className="ring-1"></div>
                    <p>Loading...</p>
                </div>
            </div>
        </div>
    );
}

export default Loading;