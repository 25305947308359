import * as React from "react";
import * as ReactDOM from 'react-dom/client';
import './assets/scss/index.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/js/dist/dropdown';
import 'react-tooltip/dist/react-tooltip.css'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './assets/scss/theme.scss';
import 'react-toastify/dist/ReactToastify.css';
import App from './views/App';
import rootConfigProps from "./auth/customProps";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, useNavigate, } from 'react-router-dom';
import { RootContext } from './contexts/rootContext';
import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react';
import { useEffect, useState } from "react";
import {ToastContainer} from "react-toastify";

const queryParams = new URLSearchParams(window.location.search);
 const tenant = queryParams.get('tenant') ?? global.sessionStorage.getItem("tenant");
 const client = queryParams.get('client') ?? global.sessionStorage.getItem("client");

 const corporateLogin = (tenant && client);
 console.log("Corporate Login" + corporateLogin);
 if (corporateLogin) {
     global.sessionStorage.setItem("tenant", tenant);
     global.sessionStorage.setItem("client", client);
 }

const ProtectedApp = ({component, ...args}) => {
    const Component = withAuthenticationRequired(component, args)
    return <Component/>
}

const Auth0ProviderWithRedirectCallback = ({children}) => {
    const navigate = useNavigate();
    const onRedirectCallback = (appState) => {
        navigate((appState && appState.returnTo) || window.location.pathname);
    };

    const [auth0Config, setAuth0Config] = useState(null);
    const fetchConfig = async () => {
        fetch("/auth_config.json")
            .then(response => response.json())
            .then(config => setAuth0Config(config))
    }

    useEffect(() => { fetchConfig() }, []);

    return (auth0Config &&
        <Auth0Provider
            onRedirectCallback={onRedirectCallback}
            domain={auth0Config.domain}
            clientId={auth0Config.client_id}
            authorizationParams={{
                audience: auth0Config.audience,
                connection: corporateLogin ? "conversity-azure-2" : auth0Config.connection,
                redirect_uri: window.location.origin
            }}>
            { children }
        </Auth0Provider>
    );
};

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <BrowserRouter basename='/'>
        <Auth0ProviderWithRedirectCallback>
            <ToastContainer
                position='top-right'
                autoClose={3000}
                closeOnClick
                pauseOnHover
            />
            <RootContext.Provider value={rootConfigProps}>
                <ProtectedApp component={App}></ProtectedApp>
            </RootContext.Provider>
        </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
