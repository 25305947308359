import { useState, useEffect } from 'react';
import { NavLink, useLocation, matchPath } from 'react-router-dom';
import RouteEnum from "../../models/RouteEnum";
import Constant from "../../models/Constant";
import _ from 'lodash';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';


function SideBarMenu() {

    const { pathname } = useLocation();

    const [menuItems, setMenuItems] = useState<any>([
        {
            heading: Constant.DeploymentBuilders,
            link: RouteEnum.Deployments,
            isExpanded: false,
            children: []
        },
        {
            heading: Constant.BrandBuilder,
            link: RouteEnum.Brands,
            isExpanded: false,
            children: []
        },
        {
            heading: Constant.QrCodeBuilder,
            link: RouteEnum.QrCodeBuilder,
            isExpanded: false,
            children: []
        }
    ]);

    useEffect(() => {

        let tempMenuItems = _.cloneDeep(menuItems);
        for (let i = 0; i < tempMenuItems.length; i++) {
            const element = tempMenuItems[i];

            if (matchPath(element.link + RouteEnum.Wildcard, pathname)) {
                element.isExpanded = true;
            } else {
                element.isExpanded = !!(element.secondaryLink && matchPath(element.secondaryLink + RouteEnum.Wildcard, pathname));
            }
        }
        setMenuItems(tempMenuItems);

    }, [pathname])

    const handleMenuChange = (prop) => {
        let tempMenuItems = _.cloneDeep(menuItems);
        for (let i = 0; i < tempMenuItems.length; i++) {
            const element = tempMenuItems[i];
            let propIndexToCompare = ':r' + (i+3) + ':';

            element.isExpanded = !!prop.includes(propIndexToCompare);
        }
        setMenuItems(tempMenuItems);
    }

    return (
        <div className="navbar-nav">

            <Accordion
                // allowMultipleExpanded
                allowZeroExpanded 
                onChange={handleMenuChange}
            >
                {menuItems.map((item) => (
                    <AccordionItem key={item.heading}
                        dangerouslySetExpanded={item.isExpanded}
                        className={item.isExpanded ? "menu-head-active": ""}
                    >
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <NavLink className="nav-item nav-link"
                                    // onClick={(e) => e.preventDefault()} 
                                    to={item.link}>
                                    {item.heading}
                                    {/* {item.heading.toLocaleUpperCase()} */}
                                </NavLink>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        {item.childrens &&
                            item.childrens.map((children) => (
                                <AccordionItemPanel key={children.heading}>
                                    <NavLink className="sidebar-menu-link" to={children.link}>
                                        {children.heading}
                                    </NavLink>
                                </AccordionItemPanel>
                            ))
                        }
                    </AccordionItem>
                ))}
            </Accordion>
        </div>
    );
}

export default SideBarMenu;