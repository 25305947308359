import React from 'react';
import { Tooltip } from 'react-tooltip'

const ToolTipMainWrapper = () => {

    const openOnClick = false;
    const closeOnEsc = true;
    const variant = 'light';

    return (
        <div className='tooltip-main-class'>
            <Tooltip
            className='custom-react-tooltip'
            classNameArrow='custom-react-tooltip-arrow'
            openOnClick={openOnClick}
            closeOnEsc={closeOnEsc}
            variant={variant}
            anchorSelect=".disp-tooltip"
          />
        </div>
    );
};

export default ToolTipMainWrapper;