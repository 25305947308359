import jwt_decode from 'jwt-decode';

const getClient = (idTokenClaims) => {
    const claims = jwt_decode(idTokenClaims.__raw);
    return {
        tenant: global.sessionStorage.getItem("tenant") ?? claims["https://conversity.com/tenant"],
        name: global.sessionStorage.getItem("client") ?? claims["https://conversity.com/client"],
    }
}

export { getClient }

