import { RootContext } from '../../contexts/rootContext';
import { useNavigate } from "react-router-dom";
import React, {useContext, useEffect, useState} from 'react';
import Constant from './../../models/Constant';
import logoutImage from '../../assets/icons/logout.png';
import profile from '../../assets/icons/profile-64.png';
import RouteEnum from '../../models/RouteEnum';
import {useAuth0} from "@auth0/auth0-react";
//import user from '../../assets/icons/user-64.png';

interface User {
    name: string
    email: string
    picture: string
    given_name: string
    family_name: string
    updated_at: string
    email_verified: boolean
}

function ProfileNav() {

    const rootData = useContext(RootContext);
    const navigate = useNavigate();

    const { user, logout } = useAuth0()

    const ProfileLine = () =>
        <div style={{display: "table-row", cursor: "auto"}}>
            <div style={{display: "table-cell", padding: "10px", verticalAlign: "middle"}}>
                <img src={user.picture} style={{borderRadius: "50%", width: "35px"}} alt={user.name}/>
            </div>
            <div style={{display: "table-cell", padding: "10px", verticalAlign: "middle"}}>
                <span style={{fontWeight: "bold"}}>{user.name}</span><br/>
                <span>{user.email}</span>
            </div>
        </div>

    return (
        <> {user &&
            <div className="profile-nav">
                <a href="#" role="button"
                   id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={user.picture} alt="avatar"
                         className="rounded-circle img-fluid" style={{width: '35px'}}/>
                </a>

                <ul className="dropdown-menu dropdown-menu-lg-end" aria-labelledby="dropdownMenuLink">
                    <li className='profile-li'><ProfileLine /></li>
                    <li onClick={() => navigate(RouteEnum.Profile)} className='profile-li'>
                        <span className="dropdown-item">&emsp;{Constant.Profile}<img className='profile' src={profile}/></span>
                    </li>
                    <li onClick={() => {
                        global.sessionStorage.clear();
                        logout({ logoutParams: { returnTo: window.location.origin }});
                    }}>
                        <span className="dropdown-item">&emsp;{Constant.Logout}<img className='profile' src={logoutImage}/></span>
                    </li>
                </ul>
            </div>
        }</>
    );
}

export default ProfileNav;