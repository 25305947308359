import "./sideBar.scss";
import { CgMenuBoxed, CgCloseR } from "react-icons/cg";
import {
  ProSidebar,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import SideBarMenu from './sideBarMenu';

const SideBar = (props: any) => {

  const { menuCollapse, menuIconClick } = props;

  return (
    <div className="leftpane">
      <div id="header">
        {menuCollapse ? (
          <div className="menu" onClick={menuIconClick}><CgMenuBoxed /></div>
        ) : (
          <div className="closemenu" onClick={menuIconClick}><CgCloseR /></div>
        )}
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader>
            <div className="sidebar-header">
            </div>
          </SidebarHeader>
          <SidebarContent>
            <SideBarMenu />
          </SidebarContent>
          <div className="verticle-line"></div>
        </ProSidebar>
      </div>
    </div>
  );
};

export default SideBar;
