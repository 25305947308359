import { NavLink } from 'react-router-dom';
import ProfileNav from './profileNav';
import {useState, useEffect, useContext} from 'react';
import ConversityIconUrl from '../../assets/icons/logo-dark-1.svg';
import NoClientUrl from '../../assets/icons/no-client-icon.png';
import {RootContext} from "../../contexts/rootContext";

function NavBar() {

  const [displayTenant, setDisplayTenant] = useState(null);
  const rootContext = useContext(RootContext);
  const client = rootContext.getLoadedClient();

  useEffect(() => {
    const defaultTenant = {
      displayName: 'Conversity',
      iconUrl: ConversityIconUrl
    }
    setDisplayTenant(defaultTenant);
  }, [])

  return (
    <div className="toppane">
      <div className="nav-container">
        <nav className="navbar navbar-expand-md navbar-light">
          <div className='main-logo-header'>
            <div className='main-logo-div'>
              <NavLink className="nav-item nav-link logo-href navbar-brand" to="/">
                {displayTenant &&
                  displayTenant.iconUrl && <img src={displayTenant.iconUrl} />
                }
              </NavLink>
            </div>
          </div>
          <div className='icon-and-profile-nav'>
            { client && <>
              <div className='sub-logo-div'>
                { client.iconUrl && <img alt="Client Icon" className="logo-img" height={48} src={client.iconUrl} /> }
                { !client.iconUrl && <img alt="Client Icon" className="logo-img" height={48} src={NoClientUrl} /> }
              </div>
              <div className='sub-logo-div'>
                <h3>{client.displayName}</h3>
              </div>
            </>}
            <ProfileNav />
          </div>
        </nav>
      </div>
    </div>
  );
}

export default NavBar;